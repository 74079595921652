//ON CONTAINER
.p-select-button.p-select-button-checked .p-select-button-slider {
  background-color: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

//ON
.p-select-button.p-select-button-checked .p-select-button-slider:before {
  background: var(--bright-blue-100) !important;
}

//OFF CONTAINER
.p-select-button .p-select-button-slider {
  background: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

//OFF
.p-select-button .p-select-button-slider:before {
  background: var(--dark-blue-100) !important;
}

.p-togglebutton.p-togglebutton-checked {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--surface-a) !important;
}
