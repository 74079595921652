.layout-header-container {
  top: 0;
  position: sticky;
  width: 100%;
  height: auto;
  z-index: 100;

  .layout-header {
    display: flex;
    height: 128px;
    background-color: var(--surface-75);

    .toogle {
      font-size: 24px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      justify-content: center;
    }

    .header-left {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;
      justify-content: center;
    }

    .header-right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 24px;
      align-content: center;
      justify-content: center;

      //div {
      //  height: 40px;
      //}
    }

    label.label-header {
      font-size: 14px;
    }
  }

  .header-footer {
    width: 100%;
    height: auto;
    background-color: var(--surface-75);
    border-bottom: 1px solid var(--surface-d);
  }
}
