/*------------------------------ MARKER ------------------------------*/
.gm-style .gm-style-iw {
  width: auto !important;
  height: auto !important;
  overflow: hidden !important;
  padding: 0 !important;
  border-radius: 5px !important;
  font-family: 'Poppins' !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

/*------------------------------ MARKER INFO WINDOW ------------------------------*/
.gm-style-iw-chr  {
  position: absolute;
  z-index: 10000;
  right: 0;
}

.marker-info {
  width: 250px;
  background: ghostwhite;
  border: 2px solid ghostwhite;
  border-radius: 5px;
  font-size: 14px;

  .marker-info-head {
    position: relative;
    height: 150px;
    background: -moz-linear-gradient(var(--background-gradient-3));
    background: -webkit-linear-gradient(var(--background-gradient-3));
    background: linear-gradient(var(--background-gradient-3));
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .marker-info-img {
      height: 160px;
      width: 100%;
    }
  }

  .marker-info-body {
    width: 250px;
    height: auto;
    padding: 5px;
    background: ghostwhite;

    .marker-info-section-head {
      display: flex;
      flex-wrap: wrap;
      border: none;
      padding: 0 0 5px 0;
      .marker-info-title {
        display: flex;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        align-content: center;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 85%;
      }
    }

    .marker-info-tags {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      width: 100%;
      .marker-info-tag {
        padding: 5px;
        margin: 1px;
        width: auto;
        text-align: center;
        border-radius: 10px;
      }
    }

    .marker-info-section {
      border-top: 1px solid var(--surface-h);
      padding: 0 0 5px 0;
      .marker-info-title {
        font-size: 16px;
        font-weight: bolder;
        margin: 10px 0 5px 0;
        letter-spacing: 0px;
      }
    }

    .marker-info-section-footer {
      display: flex;
      padding: 2px 0 0 0;
      flex-direction: row;
      align-content: center;
      justify-content: center;

      .marker-info-details {
        width: 100%;
        text-align: center;
        padding: 5px;
        border: 1px solid;
        a {
          color: var(--bright-blue-100);
          font-weight: bold;
          text-decoration: none;
        }

        &:hover {
          background-color: var(--surface-c);
        }
      }
    }
  }
}

.marker-cluster-info {
  width: 100%;
  background: ghostwhite;
  border: 2px solid ghostwhite;
  border-radius: 5px;
  font-size: 14px;
}

.cluster {
  background: var(--cluster-color);
  color: var(--surface-0);
  border-radius: 50%;
  margin: 10px;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  animation: pulse 2s infinite;
}

.cluster::before,
.cluster::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.cluster::before {
  padding: 7px;
}

.cluster::after {
  padding: 14px;
}

.cluster-1 {
  --cluster-color: #68b1ff;
}

.cluster-2 {
  --cluster-color: #ff9b00;
}

.cluster-3 {
  --cluster-color: #047dff;
}

.cluster-green {
  --cluster-color: var(--green-100);
}

.cluster-orange {
  --cluster-color: #ff9b00;
}

.cluster-red {
  --cluster-color: var(--red-100);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*------------------------------ FILTER MAP ------------------------------*/

.map-filter-container {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--surface-75);
  border: 1px solid var(--surface-d);
  width: 350px;
  height: 652px;
  padding: 12px;

  .map-filter-header {
    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  .map-filter-body {
    margin-top: 24px;

    p.name-section {
      margin: 12px;
      font-weight: bold;
    }

    ul {
      padding: 0;

      li.vehicle-type {
        position: relative;
        width: 300px;
        height: 35px;
        border-left: 5px solid var(--red-100);
        margin: 5px;
        list-style: none;
        padding: 5px;

        label.vehicle-model {
          margin-right: 100px;
        }

        .checkbox {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
