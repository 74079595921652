@charset "UTF-8";
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0 0;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

p.wide {
  width: 544px;
}

p.tiny {
  width: 272px;
}

html {
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  background-color: var(--surface-65);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
}

a {
  cursor: pointer;
  color: var(--black-80);
}

hr {
  border-top: 1px solid var(--black-20);
  margin: 0;
}

.hidden {
  display: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-center {
  text-align: center;
}

.icon {
  line-height: 64px;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.p-component {
  font-family: var(--font-family) !important;
}

.icon-pointer {
  cursor: pointer;
}

.icon-no-pointer {
  cursor: not-allowed;
}

/*------------------------------ PAGE ------------------------------*/
.content-page {
  padding: 10px;
  overflow-y: auto;
}

.map-section {
  position: relative;
}

.buttons-section {
  display: flex;
  width: 100%;
  margin: 0 0 10px 0;
  justify-content: flex-end;
}
.buttons-section .start {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}
.buttons-section .end {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

/*------------------------------ LOGIN PANNEL------------------------------*/
.login-panel {
  position: absolute;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/*------------------------------ TABLE PANNEL ------------------------------*/
th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*------------------------------ ACCORDION ------------------------------*/
/*------------------------------ OVERLAY ------------------------------*/
.p-popover .p-popover-content {
  padding: 0 !important;
}

.p-popover:before {
  border: none !important;
  border-color: none !important;
  border-bottom-color: none !important;
}

.p-popover:after {
  border: none !important;
  border-color: none !important;
  border-bottom-color: none !important;
}

/*------------------------------ TOOLTIP ------------------------------*/
.p-tooltip {
  max-width: 100% !important;
}

/*------------------------------ DRAG N DROP ------------------------------*/
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 5px rgba(0, 0, 0, 0.12);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  background: var(--a-turquoise-10);
}

.cdk-drop-list-dragging .card:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.placeholder {
  background: var(--gray-325);
  border: dotted 3px var(--black-40);
  min-height: 200px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*------------------------------ DIALOG ------------------------------*/
.dynamicDialog .p-dialog-content {
  padding: 0 1.5rem 0rem 1.5rem !important;
}

.dynamicDialog .p-dialog-title {
  font-size: 28px !important;
}

/*------------------------------ BOARD ------------------------------*/
.board-style {
  width: 100px;
  border-radius: 4px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-align: center;
}
.board-style.board-NotStarted {
  background-color: var(--board-gray);
  color: var(--board-text);
}
.board-style.board-Started {
  background-color: var(--board-gray);
  color: var(--board-text);
}
.board-style.board-Closed {
  background-color: var(--board-gray);
  color: var(--board-text);
}
.board-style.board-Completed {
  background-color: var(--board-gray);
  color: var(--board-text);
}

/*------------------------------ ISSUE SEVERITY ------------------------------*/
.severity {
  width: 100px;
  border-radius: 4px;
  padding: 0.25em 0.5rem;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: center;
}
.severity.Blocking {
  background-color: var(--severity-blocking);
  color: var(--severity-color-blocking);
}
.severity.Major {
  background-color: var(--severity-major);
  color: var(--severity-color-major);
}
.severity.Minor {
  background-color: var(--severity-minor);
  color: var(--severity-color-minor);
}

.splash-screen {
  background: -moz-linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  background: -webkit-linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  background: linear-gradient(180deg, #1dece4 0%, #095bfa 100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: left 0.5s;
}
.splash-screen .splash-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.splash-screen .splash-inner .logo {
  background: url("../../assets/logo/logo-vaimoo-white.svg");
  background-repeat: no-repeat;
  max-width: 100%;
  background-position: center;
  background-size: contain;
  width: 600px;
  height: 120px;
}
.splash-screen .splash-inner .label {
  margin-top: 32px;
  color: var(--surface-0);
  font-size: 2.5em;
  font-family: "Poppins" !important;
}
.splash-screen .splash-inner .loader {
  background: url("../../assets/sidebar/three-dots.svg");
  background-repeat: no-repeat;
  max-width: 100%;
  background-position: center;
  background-size: contain;
  width: 80px;
  height: 80px;
  margin-top: -20px;
}

.spinner {
  position: fixed;
  z-index: 1100;
  height: 150px;
  width: 150px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--maskbg);
}
.spinner .spinner-logo {
  width: 90px;
  height: 90px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-image: url("../../assets/sidebar/logo.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/*------------------------------ SPINNER NG PRIME OVERRIDE ------------------------------*/
.spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%, 0% {
    stroke: var(--a-turquoise-20);
  }
  40% {
    stroke: var(--a-turquoise-100);
  }
  66% {
    stroke: var(--bright-blue-100);
  }
  80%, 90% {
    stroke: var(--dark-blue-100);
  }
}
@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
/*------------------------------ NG-PROGRESSBAR ------------------------------*/
.ng-bar-placeholder {
  height: 8px !important;
}

.ng-bar {
  background: -moz-linear-gradient(var(--background-gradient-2)) !important;
  background: -webkit-linear-gradient(var(--background-gradient-2)) !important;
  background: linear-gradient(var(--background-gradient-2)) !important;
}

.layout-header-container {
  top: 0;
  position: sticky;
  width: 100%;
  height: auto;
  z-index: 100;
}
.layout-header-container .layout-header {
  display: flex;
  height: 128px;
  background-color: var(--surface-75);
}
.layout-header-container .layout-header .toogle {
  font-size: 24px;
}
.layout-header-container .layout-header .menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  justify-content: center;
}
.layout-header-container .layout-header .header-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  justify-content: center;
}
.layout-header-container .layout-header .header-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  align-content: center;
  justify-content: center;
}
.layout-header-container .layout-header label.label-header {
  font-size: 14px;
}
.layout-header-container .header-footer {
  width: 100%;
  height: auto;
  background-color: var(--surface-75);
  border-bottom: 1px solid var(--surface-d);
}

.layout-sidebar.open {
  width: 320px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-sidebar.close {
  width: 64px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--surface-75);
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-right: 1px solid var(--surface-d);
  display: flex;
  flex-direction: column;
}
.layout-sidebar a.sidebar-logo {
  width: 64px;
  height: 70px;
  color: var(--surface-0);
  display: block;
  text-decoration: none !important;
  list-style: none;
  position: relative;
  background-image: url("../../assets/sidebar/logo.svg");
}
.layout-sidebar a.sidebar-logo:hover {
  background-color: var(--a-turquoise-60);
}
.layout-sidebar .sidebar {
  width: 64px;
  height: 100vh;
  background: -moz-linear-gradient(var(--background-gradient-1));
  background: -webkit-linear-gradient(var(--background-gradient-1));
  background: linear-gradient(var(--background-gradient-1));
}
.layout-sidebar .sidebar ul.sidebar-menu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  margin: -6px 0 0 0;
  padding: 0px;
}
.layout-sidebar .sidebar ul.sidebar-menu li.sidebar-items {
  list-style: none;
}
.layout-sidebar .sidebar ul.sidebar-menu li.sidebar-items a.sidebar-link {
  height: 64px;
  color: var(--surface-0);
  border-top: 1px solid var(--a-turquoise-20);
  display: block;
  text-decoration: none !important;
  list-style: none;
  position: relative;
}
.layout-sidebar .sidebar ul.sidebar-menu li.sidebar-items a.sidebar-link:hover {
  background-color: var(--a-turquoise-60);
}
.layout-sidebar .sidebar ul.sidebar-menu li.sidebar-items .active-sidebar {
  color: var(--dark-blue-100);
  background-color: var(--surface-0);
  border-right: 1px solid var(--surface-c);
}
.layout-sidebar .spacer {
  flex: 1;
  list-style: none;
  border-top: 1px solid var(--a-turquoise-20);
}
.layout-sidebar .footer-version {
  width: 100%;
  height: 64px;
  padding: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: var(--surface-125);
  border-top: 1px solid var(--black-40);
  align-items: center;
  text-align: center;
  justify-content: center;
}

.layout-navbar ul.navbar {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: -moz-linear-gradient(var(--background-gradient-2));
  background: -webkit-linear-gradient(var(--background-gradient-2));
  background: linear-gradient(var(--background-gradient-2));
}
.layout-navbar ul.navbar a.navbar-logo {
  width: 64px;
  height: 64px;
  color: var(--surface-0);
  display: block;
  text-decoration: none !important;
  border-right: 1px solid var(--a-turquoise-20);
  list-style: none;
  position: relative;
  background-image: url("../../assets/sidebar/logo.svg");
}
.layout-navbar ul.navbar a.navbar-logo:hover {
  background-color: var(--a-turquoise-60);
}
.layout-navbar ul.navbar li.navbar-items {
  float: left;
  width: 64px;
  height: 64px;
}
.layout-navbar ul.navbar li.navbar-items a.navbar-link {
  height: 64px;
  color: var(--surface-0);
  border-right: 1px solid var(--a-turquoise-20);
  display: block;
  text-decoration: none !important;
  list-style: none;
  position: relative;
}
.layout-navbar ul.navbar li.navbar-items a.navbar-link:hover {
  background-color: var(--a-turquoise-60);
}
.layout-navbar ul.navbar .active {
  color: var(--dark-blue-100);
  background-color: var(--surface-0);
  cursor: pointer;
}
.layout-navbar ul.navbar .spacer {
  flex: 1;
  list-style: none;
  border-right: 1px solid var(--a-turquoise-20);
}
.layout-navbar .dropdown-mobile-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: var(--surface-125);
  min-width: 160px;
  box-shadow: var(--box-shadow-5);
  -webkit-box-shadow: var(--box-shadow-5);
  z-index: 999;
}
.layout-navbar .dropdown-mobile-content a {
  color: var(--black-100);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.layout-navbar .dropdown-mobile-content a :hover {
  background-color: var(--surface-65);
}
.layout-navbar li.dropdown-mobile:hover .dropdown-mobile-content {
  display: block;
}

.layout-container-menu.open {
  width: 250px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-container-menu.close {
  width: 0;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-container-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--surface-75);
  user-select: none;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-right: 1px solid var(--surface-d);
  z-index: 999;
}
.layout-container-menu .layout-menu {
  overflow-y: auto;
  flex-grow: 1;
}
.layout-container-menu .layout-menu .section-header {
  height: 64px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--surface-d);
}
.layout-container-menu .layout-menu .section-title {
  display: flex;
  width: 100%;
  height: 64px;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  padding: 10px;
  justify-content: center;
}
.layout-container-menu .layout-menu .section-close {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: center;
  padding: 10px;
  justify-content: center;
}
.layout-container-menu .layout-menu .section-close i {
  font-size: 20px;
}
.layout-container-menu .layout-menu .section-close i:hover {
  color: var(--bright-blue-100);
  border-radius: 50%;
}
.layout-container-menu .layout-menu .section-menu {
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.layout-container-menu .layout-menu .section-menu .mainmenu,
.layout-container-menu .layout-menu .section-menu .child,
.layout-container-menu .layout-menu .section-menu .subChild,
.layout-container-menu .layout-menu .section-menu .subSubChild {
  list-style: none;
  padding: 5px;
  margin: 0;
}
.layout-container-menu .layout-menu .section-menu .mainmenu a {
  display: block;
  text-decoration: none;
  padding: 5px;
}
.layout-container-menu .layout-menu .section-menu .active-link {
  color: var(--bright-blue-100);
  font-weight: bold;
}
.layout-container-menu .layout-menu .section-menu .mainmenu a:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
  font-weight: bold;
}
.layout-container-menu .layout-menu .section-menu .child a:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
  font-weight: bold;
}
.layout-container-menu .layout-menu .section-menu .subChild a:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
  font-weight: bold;
}
.layout-container-menu .layout-menu .section-menu .subSubChild a:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
  font-weight: bold;
}
.layout-container-menu .layout-menu .section-menu .mainmenu li:hover .child,
.layout-container-menu .layout-menu .section-menu .child li:hover .subChild,
.layout-container-menu .layout-menu .section-menu .subChild li:hover .subSubChild {
  display: block;
  height: 100%;
  max-height: 1000px;
}
.layout-container-menu .layout-menu .section-menu .child,
.layout-container-menu .layout-menu .section-menu .subChild,
.layout-container-menu .layout-menu .section-menu .subSubChild {
  overflow: hidden;
  max-height: 0;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
}

.va-user-container {
  display: flex;
  align-items: center;
}

.va-user-circle {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid var(--surface-0);
  color: var(--surface-0);
  font-weight: bold;
  background: var(--bright-blue-100);
}

.user-header {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 15px;
  color: var(--text-color);
}
.user-header .user-image {
  margin: 12px 12px 12px 0;
}
.user-header .user-information {
  display: flex;
  flex-direction: column;
}
.user-header .user-information h6.user-title {
  margin: 12px 0 0 0;
}
.user-header .user-information p.user-subtitle {
  color: var(--black-80);
  font-size: 12px;
  margin: 4px 0 0 0;
}

.user-language {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  color: var(--text-color);
  border-top: 1px solid #d8d8d8;
}

.user-menu {
  border-top: 1px solid var(--surface-h);
  padding: 5px 0 5px 0;
}
.user-menu .user-logout {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.user-menu li.user-link {
  padding: 24px;
  display: flex;
  height: 40px;
  color: var(--black-80);
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.user-menu li.user-link:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
  font-weight: bold;
}
.user-menu li.user-link .user-icon {
  font-size: 14px;
}

.section {
  position: absolute;
  top: 0;
  left: 64px;
  width: 256px;
  height: 100%;
}

.section-header {
  height: 64px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--surface-d);
}

.section-title {
  display: flex;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  padding: 10px;
  justify-content: center;
}

.section-close {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: center;
  padding: 10px;
  justify-content: center;
}
.section-close i {
  font-size: 20px;
}
.section-close i:hover {
  color: var(--bright-blue-100);
  border-radius: 50%;
}

i.section-icon {
  font-size: 18px;
  font-weight: bold;
  float: right;
}

ul.section-menu {
  padding: 0px;
}
ul.section-menu li {
  list-style: none;
}
ul.section-menu li a.section-link {
  padding: 12px;
  display: block;
  text-decoration: none !important;
  list-style: none;
  position: relative;
}
ul.section-menu li a.section-link:hover {
  color: var(--bright-blue-100);
  background-color: var(--surface-c);
  font-weight: bold;
}
ul.section-menu li .active-section {
  color: var(--bright-blue-100);
  font-weight: bold;
}

ul.section-menu li.section-item {
  display: block;
}

ul.section-menu::before li.section-item {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 0;
  bottom: 0;
}

.va-button-primary {
  color: var(--surface-0) !important;
  background-color: var(--primary-blue) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
}
.va-button-primary:hover {
  background-color: var(--dark-blue-100) !important;
}
.va-button-primary:focus {
  border: 1px solid var(--a-turquoise-100) !important;
}
.va-button-primary:disabled {
  color: var(--black-40) !important;
  background-color: var(--black-20) !important;
  cursor: not-allowed !important;
}

.va-button-secondary {
  color: var(--primary-blue) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
}
.va-button-secondary:hover {
  color: white !important;
  background-color: var(--primary-blue) !important;
}
.va-button-secondary:focus {
  border: 1px solid var(--a-turquoise-100) !important;
}
.va-button-secondary:disabled {
  color: var(--black-40) !important;
  background-color: var(--black-20) !important;
  cursor: not-allowed !important;
}

.va-button-tertiary {
  color: var(--black-100) !important;
  background-color: var(--surface-0) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: 1px solid var(--black-20) !important;
}
.va-button-tertiary:hover {
  color: var(--black-100) !important;
  border: 1px solid var(--primary-blue) !important;
}
.va-button-tertiary:focus {
  color: var(--primary-blue) !important;
  border: 1px solid var(--a-turquoise-100) !important;
}
.va-button-tertiary:disabled {
  color: var(--black-40) !important;
  cursor: not-allowed !important;
}

.va-button-ghost {
  color: var(--primary-blue) !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
}
.va-button-ghost:hover {
  background-color: var(--dark-blue-20) !important;
}
.va-button-ghost:focus {
  border: 1px solid var(--a-turquoise-100) !important;
}
.va-button-ghost:disabled {
  color: var(--black-40) !important;
  cursor: not-allowed !important;
}

.va-button-filter-item {
  color: var(--primary-blue) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 0 !important;
}
.va-button-filter-item:hover {
  color: var(--primary-blue) !important;
  border-bottom: 2px solid var(--primary-blue) !important;
}
.va-button-filter-item:focus {
  color: var(--primary-blue) !important;
  border-bottom: 2px solid var(--primary-blue) !important;
}

.va-button-filter {
  color: var(--primary-blue) !important;
  background-color: var(--dark-blue-20) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 0 !important;
}
.va-button-filter:hover {
  color: var(--surface-0) !important;
  background-color: var(--primary-blue) !important;
}
.va-button-filter:focus {
  border: 1px solid var(--a-turquoise-100) !important;
}
.va-button-filter:disabled {
  color: var(--black-40) !important;
  background-color: var(--black-20) !important;
  cursor: not-allowed !important;
}

.va-button {
  box-shadow: none !important;
  margin: 0 2px;
  width: auto !important;
  min-width: 100px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.va-button-only-icon {
  box-shadow: none !important;
  margin: 2px !important;
  width: 48px !important;
  height: 48px !important;
}

.p-select-button.p-select-button-checked .p-select-button-slider {
  background-color: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

.p-select-button.p-select-button-checked .p-select-button-slider:before {
  background: var(--bright-blue-100) !important;
}

.p-select-button .p-select-button-slider {
  background: var(--surface-0) !important;
  border: 1px solid var(--black-20);
}

.p-select-button .p-select-button-slider:before {
  background: var(--dark-blue-100) !important;
}

.p-togglebutton.p-togglebutton-checked {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--surface-a) !important;
}

/*------------------------------ LAYOUT ------------------------------*/
.layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.layout-wrapper .layout-content.open {
  margin-left: 320px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-wrapper .layout-content.close {
  margin-left: 64px;
  transition: 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-wrapper .layout-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.layout-wrapper .layout-content .content-section {
  padding: 2rem 4rem;
  flex-grow: 1;
}

.va-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

i.va-icon-size {
  font-size: 25px;
}
i.va-icon-size.va-active {
  color: #047dff;
}

.va-user {
  background-image: url(../../assets/sidebar/user.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-user.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-accounting {
  background-image: url(../../assets/sidebar/accounting.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-accounting.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-assets {
  background-image: url(../../assets/sidebar/assets.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-assets.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-config {
  background-image: url(../../assets/sidebar/config.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-config.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-search {
  background-image: url(../../assets/sidebar/search.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-search.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-setting {
  background-image: url(../../assets/sidebar/settings.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-setting.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-sharing {
  background-image: url(../../assets/sidebar/sharing.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-sharing.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-tenant {
  background-image: url(../../assets/sidebar/tenant.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-tenant.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-trips {
  background-image: url(../../assets/sidebar/trips.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-trips.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-admin {
  background-image: url(../../assets/sidebar/admin.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-admin.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-monitoring {
  background-image: url(../../assets/sidebar/monitoring.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-monitoring.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-maintenance {
  background-image: url(../../assets/sidebar/settings.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-maintenance.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-system {
  background-image: url(../../assets/sidebar/system.svg);
  filter: invert(100%) sepia(8%) saturate(3%) hue-rotate(135deg) brightness(107%) contrast(100%);
}
.va-system.va-active {
  filter: invert(34%) sepia(92%) saturate(3224%) hue-rotate(200deg) brightness(102%) contrast(99%);
}

.va-logo {
  background-image: url(../../assets/sidebar/logo.svg);
}

/* CHECKBOX */
.checkbox-content {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

/* TEXT FIELD */
.p-inputtext:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* DROPDOWN */
.p-select {
  width: 240px;
}

.p-select:not(.p-disabled):hover {
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-select:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* MULTISELECTION */
.p-multiselect-label:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-multiselect:not(.p-disabled):hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

p-inputgroup-addon.p-inputgroupaddon {
  border-radius: 3px;
  border: 1px solid lightgray;
}

p-inputgroup.p-inputgroup {
  height: 37px;
}

/*------------------------------ SKELETON ------------------------------*/
.skeleton-loader-background {
  width: 100%;
  height: 15px;
  display: block;
  background: lightgray;
}

.skeleton-loader-gradient {
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), #dee2e6;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-position: 0 0;
}

.skeleton-loader-full {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), #dee2e6;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

.skeleton-loader-empty:empty {
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), #dee2e6;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
/*------------------------------ PROGRESSBAR ------------------------------*/
.p-progressbar {
  margin: 8px 0 0 0;
  width: 72% !important;
  float: right;
}

.p-progressbar-determinate {
  height: 10px !important;
}

.p-element.red .p-progressbar-value {
  background: var(--red-100) !important;
}

.p-element.yellow .p-progressbar-value {
  background: var(--yellow-100) !important;
}

.p-element.green .p-progressbar-value {
  background: var(--green-100) !important;
}

.percentage {
  width: 25%;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}

.color-gray {
  color: var(--black-20);
}

.color-red {
  color: var(--red-100);
}

.color-yellow {
  color: var(--yellow-100);
}

.color-green {
  color: var(--green-100);
}

/*------------------------------ MARKER ------------------------------*/
.gm-style .gm-style-iw {
  width: auto !important;
  height: auto !important;
  overflow: hidden !important;
  padding: 0 !important;
  border-radius: 5px !important;
  font-family: "Poppins" !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

/*------------------------------ MARKER INFO WINDOW ------------------------------*/
.gm-style-iw-chr {
  position: absolute;
  z-index: 10000;
  right: 0;
}

.marker-info {
  width: 250px;
  background: ghostwhite;
  border: 2px solid ghostwhite;
  border-radius: 5px;
  font-size: 14px;
}
.marker-info .marker-info-head {
  position: relative;
  height: 150px;
  background: -moz-linear-gradient(var(--background-gradient-3));
  background: -webkit-linear-gradient(var(--background-gradient-3));
  background: linear-gradient(var(--background-gradient-3));
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.marker-info .marker-info-head .marker-info-img {
  height: 160px;
  width: 100%;
}
.marker-info .marker-info-body {
  width: 250px;
  height: auto;
  padding: 5px;
  background: ghostwhite;
}
.marker-info .marker-info-body .marker-info-section-head {
  display: flex;
  flex-wrap: wrap;
  border: none;
  padding: 0 0 5px 0;
}
.marker-info .marker-info-body .marker-info-section-head .marker-info-title {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  align-content: center;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 85%;
}
.marker-info .marker-info-body .marker-info-tags {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  width: 100%;
}
.marker-info .marker-info-body .marker-info-tags .marker-info-tag {
  padding: 5px;
  margin: 1px;
  width: auto;
  text-align: center;
  border-radius: 10px;
}
.marker-info .marker-info-body .marker-info-section {
  border-top: 1px solid var(--surface-h);
  padding: 0 0 5px 0;
}
.marker-info .marker-info-body .marker-info-section .marker-info-title {
  font-size: 16px;
  font-weight: bolder;
  margin: 10px 0 5px 0;
  letter-spacing: 0px;
}
.marker-info .marker-info-body .marker-info-section-footer {
  display: flex;
  padding: 2px 0 0 0;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.marker-info .marker-info-body .marker-info-section-footer .marker-info-details {
  width: 100%;
  text-align: center;
  padding: 5px;
  border: 1px solid;
}
.marker-info .marker-info-body .marker-info-section-footer .marker-info-details a {
  color: var(--bright-blue-100);
  font-weight: bold;
  text-decoration: none;
}
.marker-info .marker-info-body .marker-info-section-footer .marker-info-details:hover {
  background-color: var(--surface-c);
}

.marker-cluster-info {
  width: 100%;
  background: ghostwhite;
  border: 2px solid ghostwhite;
  border-radius: 5px;
  font-size: 14px;
}

.cluster {
  background: var(--cluster-color);
  color: var(--surface-0);
  border-radius: 50%;
  margin: 10px;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  -webkit-box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  animation: pulse 2s infinite;
}

.cluster::before,
.cluster::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.cluster::before {
  padding: 7px;
}

.cluster::after {
  padding: 14px;
}

.cluster-1 {
  --cluster-color: #68b1ff;
}

.cluster-2 {
  --cluster-color: #ff9b00;
}

.cluster-3 {
  --cluster-color: #047dff;
}

.cluster-green {
  --cluster-color: var(--green-100);
}

.cluster-orange {
  --cluster-color: #ff9b00;
}

.cluster-red {
  --cluster-color: var(--red-100);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/*------------------------------ FILTER MAP ------------------------------*/
.map-filter-container {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--surface-75);
  border: 1px solid var(--surface-d);
  width: 350px;
  height: 652px;
  padding: 12px;
}
.map-filter-container .map-filter-header .close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.map-filter-container .map-filter-body {
  margin-top: 24px;
}
.map-filter-container .map-filter-body p.name-section {
  margin: 12px;
  font-weight: bold;
}
.map-filter-container .map-filter-body ul {
  padding: 0;
}
.map-filter-container .map-filter-body ul li.vehicle-type {
  position: relative;
  width: 300px;
  height: 35px;
  border-left: 5px solid var(--red-100);
  margin: 5px;
  list-style: none;
  padding: 5px;
}
.map-filter-container .map-filter-body ul li.vehicle-type label.vehicle-model {
  margin-right: 100px;
}
.map-filter-container .map-filter-body ul li.vehicle-type .checkbox {
  position: absolute;
  right: 0;
}

/*------------------------------ BADGE ------------------------------*/
.badge {
  display: block;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

.badge-power {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.badge-charge {
  display: block;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

/*------------------------------ POWER ------------------------------*/
.power-green {
  background: var(--green-100);
}

.power-grey {
  background: var(--black-20);
}

/*------------------------------ PROGRESS ------------------------------*/
.progress-green {
  background: var(--green-100);
  color: var(--green-100);
}

.progress-grey {
  background: var(--black-20);
  color: var(--black-20);
}

.progress-red {
  background: var(--red-100);
  color: var(--red-100);
}

.progress-yellow {
  background: var(--yellow-100);
  color: var(--yellow-100);
}

/*------------------------------ CHARGE ------------------------------*/
.charge-green {
  border: 2px solid var(--green-100);
  background: var(--green-20);
  color: var(--green-100);
}

.charge-grey {
  border: 2px solid var(--black-20);
  background: var(--black-20);
  color: var(--black-100);
}

.charge-red {
  border: 2px solid var(--red-100);
  background: var(--red-20);
  color: var(--red-100);
}

.charge-yellow {
  border: 2px solid var(--yellow-100);
  background: var(--yellow-20);
  color: var(--yellow-110);
}

/*------------------------------ COLORS ------------------------------*/
.green {
  background: var(--green-100);
  color: var(--green-100);
}

.grey {
  background: var(--black-20);
  color: var(--black-100);
}

.red {
  background: var(--red-100);
  color: var(--red-100);
}

.yellow {
  background: var(--yellow-100);
  color: var(--yellow-100);
}

.blue {
  background: var(--bright-blue-100);
  color: var(--surface-0);
}

.color-grey {
  color: var(--black-20);
}

.color-black {
  color: var(--black-100);
}

.color-green {
  color: var(--green-100);
}

.filter-container {
  width: 100%;
  display: flex;
}
.filter-container .filter-selected {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.filter-container .filter-selected .filter-selected-span {
  display: flex;
  margin: 10px;
}
.filter-container .filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.advanced-filters {
  position: absolute;
  right: 24px;
}

.filter-badge-label {
  width: auto;
  height: 48px;
  color: var(--bright-blue-100);
  background-color: var(--bright-blue-20);
  font-size: 12px;
  font-weight: 600;
  margin: 2px;
  padding: 10px;
  text-align: center;
}
.filter-badge-label .value {
  margin-left: 10px;
}

.filter-badge-op {
  width: auto;
  height: 24px;
  display: flex;
  color: var(--surface-0);
  background-color: var(--green-100);
  font-size: 12px;
  margin: 2px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.label-container {
  display: flex;
  width: 100%;
}
.label-container .start {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}
.label-container .end {
  display: flex;
  height: 30px;
  justify-content: flex-end;
  width: 100%;
}

.badge-content {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.badge-operator {
  background-color: var(--green-100);
  width: 90px;
  border-radius: 10px;
  padding: 1px;
  color: var(--surface-0);
  font-size: 11px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
}

.badge-type {
  background-color: var(--bright-blue-100);
  width: 90px;
  border-radius: 10px;
  padding: 1px;
  color: var(--surface-0);
  font-size: 11px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
}

ul.item-ot {
  list-style: none;
  padding: 0;
}
ul.item-ot li {
  list-style: none;
}
ul.item-ot li:hover {
  background: var(--surface-c);
}
ul.item-ot li.desc {
  margin-left: 5px;
  padding: 0.2rem 1rem;
  gap: 0.5rem;
  display: flex;
  color: var(--gray-725);
}

.display-none {
  display: none;
}

.panel-fieldset {
  width: 100%;
  border: 1px solid var(--bright-blue-100);
  border-radius: 5px;
  padding: 1px;
  margin: 2px;
}
.panel-fieldset .panel-legend {
  border: 1px solid var(--bright-blue-100);
  background-color: var(--bright-blue-100);
  color: var(--surface-0);
  margin-left: 1em;
  padding: 0.2em 0.8em;
}

.panel-fieldset-no-border {
  width: 100%;
  border: none;
}

.panel-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  margin: 5px auto;
  list-style: none;
  padding: 0;
}
.panel-table .spacer {
  width: 14px;
  height: 14px;
  font-size: 14px;
}
.panel-table label {
  width: 50%;
  max-width: 50px;
  font-weight: 600;
}
.panel-table label:after {
  content: ":";
}

.panel-table > li.list-panel-table-skeleton {
  display: table-row;
  margin: 5px auto;
}

.panel-table > li.list-panel-table:nth-child(even):last-child {
  border: 0;
}

.panel-table > li.list-panel-table {
  display: table-row;
  margin: 5px auto;
  background-color: var(--surface-0);
  border-bottom: 1px solid var(--surface-c);
}
.panel-table > li.list-panel-table:last-child {
  border: 0;
}
.panel-table > li.list-panel-table:hover {
  background: var(--surface-c);
}

.panel-table > li.list-panel-table:nth-child(even) {
  background: var(--surface-g);
}
.panel-table > li.list-panel-table:nth-child(even):hover {
  background: var(--surface-c);
}

.panel-table > li.list-panel-table > * {
  display: table-cell;
  text-align: left;
  padding: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-table > li.list-panel-table-skeleton > * {
  display: table-cell;
  text-align: left;
  padding: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-empty-message {
  width: 100%;
  height: 200px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

/*------------------------------ TABLE ------------------------------*/
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

/*------------------------------ TABLE MENU ------------------------------*/
button {
  height: 30px;
}

/*------------------------------ COLUMN OVERLAY ------------------------------*/
ul.column-settings {
  font-size: 14px;
  list-style: none;
  padding: 0;
}
ul.column-settings li.column-item {
  list-style: none;
}
ul.column-settings li.column-item:hover {
  background: #e9ecef;
}
ul.column-settings li.column-item .item {
  margin-left: 5px;
  padding: 0.2rem 1rem;
  color: #495057;
}

/*------------------------------ OTHER ------------------------------*/
.settings {
  width: 2.25em;
}

.icon-loader {
  font-size: 24px;
  margin: 10px;
}

.emptymessage {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background-color: var(--surface-65);
}
.emptymessage i {
  font-size: 50px;
}

.value-content {
  display: flex;
  width: 100%;
  height: 25px;
}
.value-content .value {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.value-content .icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.layout-config {
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  display: block;
  width: 400px;
  z-index: 990;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transform: translateX(100%);
  backface-visibility: hidden;
}
.layout-config.layout-config-active {
  transform: translateX(0);
}
.layout-config.layout-config-active .layout-config-content-wrapper .layout-config-button i {
  transform: rotate(0deg);
}
.layout-config .layout-config-content-wrapper {
  position: relative;
  height: 100%;
  box-shadow: var(--box-shadow-6);
  -webkit-box-shadow: var(--box-shadow-6);
  background-color: var(--surface-0);
}
.layout-config .layout-config-content-wrapper .layout-config-button {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: var(--bright-blue-100);
  color: var(--surface-0);
  text-align: center;
  top: 270px;
  left: -51px;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  outline: 0 none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: var(--box-shadow-7);
  -webkit-box-shadow: var(--box-shadow-7);
}
.layout-config .layout-config-content-wrapper .layout-config-button i {
  font-size: 26px;
  line-height: inherit;
  cursor: pointer;
  transform: rotate(360deg);
  transition: transform 1s;
}
.layout-config a {
  font-weight: 600;
}
.layout-config a:hover {
  text-decoration: underline;
}
.layout-config .layout-config-content {
  overflow: auto;
  height: 100%;
  padding: 2rem;
}
.layout-config .config-scale {
  display: flex;
  align-items: center;
}
.layout-config .config-scale .p-button {
  margin-right: 0.5rem;
}
.layout-config .config-scale i {
  margin-right: 0.5rem;
  font-size: 0.75rem;
}
.layout-config .config-scale i.scale-active {
  font-size: 1.25rem;
}
.layout-config .layout-config-close {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  right: 20px;
  top: 20px;
  z-index: 999;
  background-color: var(--surface-0);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
}
.layout-config .layout-config-close i {
  line-height: inherit;
  font-size: 14px;
}
.layout-config .layout-config-close:hover {
  transform: scale(1.1);
}

.fieldset-config {
  border: 1px solid var(--surface-65);
  border-radius: 5px;
  margin: 30px 5px 30px 5px;
  padding: 10px;
}
.fieldset-config .panel-legend {
  border: 1px solid var(--surface-65);
  background-color: var(--surface-65);
  margin-left: 1em;
  padding: 0.2em 0.8em;
  font-weight: bold;
  font-size: 18px;
}

footer {
  display: flex;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--surface-0);
  box-shadow: var(--box-shadow-1);
  -webkit-box-shadow: var(--box-shadow-1);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.notFound,
.notAuthorized {
  width: 100%;
  height: 100%;
  padding: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: -moz-linear-gradient(var(--background-gradient-1));
  background: -webkit-linear-gradient(var(--background-gradient-1));
  background: linear-gradient(var(--background-gradient-1));
}
.notFound .logo,
.notAuthorized .logo {
  background: url("../../assets/logo/logo-vaimoo-white.svg");
  background-repeat: no-repeat;
  max-width: 90%;
  background-position: center;
  background-size: contain;
  width: 600px;
  height: 120px;
}
.notFound .icon-loader,
.notAuthorized .icon-loader {
  margin: 5px;
}
.notFound .button a,
.notAuthorized .button a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--bright-blue-100);
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: var(--surface-0);
  font-weight: 700;
  -webkit-box-shadow: var(--box-shadow-8);
  box-shadow: var(--box-shadow-8);
}
.notFound .button a:hover,
.notAuthorized .button a:hover {
  background-color: var(--dark-blue-100);
}

.circle-line {
  fill: var(--surface-125);
}

.number-inf {
  fill: var(--bright-blue-80);
}

.number-sup {
  fill: var(--bright-blue-100);
}

.pomello {
  fill: var(--black-100);
}

.sellino {
  fill: var(--black-100);
}

.telaio-anteriore {
  fill: var(--black-60);
}

.telaio-centrale {
  fill: var(--black-100);
  opacity: 0.4;
}

.ruote {
  fill: var(--black-80);
}

/* animations */
.wheel {
  animation: wheel-rotate 6s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes wheel-rotate {
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    transform: rotate(960deg);
  }
}
#bike {
  animation: bike-anim 6s ease infinite;
}

@keyframes bike-anim {
  0% {
    transform: translateX(-1300px);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: translateX(1300px);
  }
}
.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s;
}

.circle.c2 {
  animation-duration: 3s;
}

.circle.c3 {
  animation-duration: 1s;
}

.circle.c4 {
  animation-duration: 1s;
}

.circle.c5 {
  animation-duration: 2s;
}

.circle.c6 {
  animation-duration: 3s;
}

@keyframes circle-anim {
  50% {
    transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}
.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(0.98);
  }
}
ul.panel-list {
  padding: 0;
  display: flex;
  flex-direction: column;
}
ul.panel-list li.panel-list-item {
  height: 25px;
  color: var(--black-80);
  display: flex;
  flex-direction: row;
  align-items: center;
}
ul.panel-list li.panel-list-item:hover {
  background: var(--surface-c);
  color: var(--bright-blue-100);
}

ul.panel-list li.panel-list-item:before {
  content: "•";
  font-size: 24px;
  margin-left: 18px;
  margin-right: 5px;
}

.tab-container {
  padding: 0 0 0 24px;
}

.tablink:first-child {
  border-top-left-radius: 3px;
}

.tablink:last-child {
  border-top-right-radius: 3px;
}

.tablink {
  width: 100px;
  height: auto;
  color: var(--bright-blue-100);
  background: var(--surface-65);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.tablink:hover {
  background: var(--bright-blue-100);
  color: var(--surface-0);
}

.tab-active {
  background: var(--bright-blue-100);
  color: var(--surface-0);
}

.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

@media screen and (min-width: 1729px) {
  .layout-header-container .layout-header .menu {
    display: none;
  }
  .layout-content-inner {
    width: 100%;
    margin: 0;
  }
  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .layout-header-container .layout-header .menu {
    display: none;
  }
  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}
@media screen and (min-width: 960px) {
  .layout-header-container .layout-header .menu {
    display: none;
  }
  .layout-container-menu {
    display: none;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
}
@media screen and (max-width: 959px) {
  .splash-screen .splash-inner .logo {
    width: 300px;
    height: 120px;
  }
  .layout-sidebar {
    top: 0;
    z-index: 1102;
    height: 100%;
    transform: translateX(-110%);
  }
  .layout-wrapper .layout-content.open {
    margin-left: 0;
  }
  .layout-wrapper .layout-content.close {
    margin-left: 0;
  }
  .layout-wrapper .layout-content {
    margin-left: 0;
  }
  .repair-sidebar .repair-sidebar.repair-sidebar-active {
    width: 100%;
  }
  .notFound,
  .notAuthorized {
    padding: 30px;
  }
}