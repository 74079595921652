/* CHECKBOX */
.checkbox-content {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

/* TEXT FIELD */

.p-inputtext:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:hover {
  box-shadow: none !important;
  border-color: var(--a-turquoise-100) !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* DROPDOWN */

.p-select {
  width: 240px;
}

.p-select:not(.p-disabled):hover {
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-select:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

/* MULTISELECTION */

.p-multiselect-label:is(.p-focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

.p-multiselect:not(.p-disabled):hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: 1px solid var(--a-turquoise-100) !important;
}

// customization from primeng 18
p-inputgroup-addon.p-inputgroupaddon {
    border-radius: 3px;
    border: 1px solid lightgray
}

p-inputgroup.p-inputgroup {
    height: 37px;
}

//// INPUT GROUP
//
//.p-inputgroup {
//  display: flex;
//  align-items: stretch;
//  width: 100%;
//}
//
//.p-inputgroup-addon {
//  background: #e9ecef;
//  color: #6c757d;
//  border-top: 1px solid #ced4da;
//  border-left: 1px solid #ced4da;
//  border-bottom: 1px solid #ced4da;
//  padding: 0.5rem 0.5rem;
//  min-width: 2.357rem;
//}
//.p-inputgroup-addon:last-child {
//  border-right: 1px solid #ced4da;
//}
//
//.p-inputgroup > .p-component,
//.p-inputgroup > .p-inputwrapper > .p-inputtext,
//.p-inputgroup > .p-float-label > .p-component {
//  border-radius: 0;
//  margin: 0;
//}
//.p-inputgroup > .p-component + .p-inputgroup-addon,
//.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
//.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
//  border-left: 0 none;
//}
//.p-inputgroup > .p-component:focus,
//.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
//.p-inputgroup > .p-float-label > .p-component:focus {
//  z-index: 1;
//}
//.p-inputgroup > .p-component:focus ~ label,
//.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
//.p-inputgroup > .p-float-label > .p-component:focus ~ label {
//  z-index: 1;
//}
//
//.p-inputgroup-addon:first-child,
//.p-inputgroup button:first-child,
//.p-inputgroup input:first-child,
//.p-inputgroup > .p-inputwrapper:first-child > .p-component,
//.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
//  border-top-left-radius: 3px;
//  border-bottom-left-radius: 3px;
//}
//
//.p-inputgroup .p-float-label:first-child input {
//  border-top-left-radius: 3px;
//  border-bottom-left-radius: 3px;
//}
//
//.p-inputgroup-addon:last-child,
//.p-inputgroup button:last-child,
//.p-inputgroup input:last-child,
//.p-inputgroup > .p-inputwrapper:last-child > .p-component,
//.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
//  border-top-right-radius: 3px;
//  border-bottom-right-radius: 3px;
//}
//
//.p-inputgroup .p-float-label:last-child input {
//  border-top-right-radius: 3px;
//  border-bottom-right-radius: 3px;
//}
//.p-inputgroup {
//  display: flex;
//  align-items: stretch;
//  width: 100%;
//}
//
//.p-inputgroup-addon {
//  background: #e9ecef;
//  color: #6c757d;
//  border-top: 1px solid #ced4da;
//  border-left: 1px solid #ced4da;
//  border-bottom: 1px solid #ced4da;
//  padding: 0.5rem 0.5rem;
//  min-width: 2.357rem;
//}
//.p-inputgroup-addon:last-child {
//  border-right: 1px solid #ced4da;
//}
